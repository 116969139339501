import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumb, Col, Container, Row, Spinner } from 'react-bootstrap'
import playerimg from "../assets/images/player.png";
import crownimg from "../assets/images/crown.png"
import winnerPic4 from "../assets/images/4.png"
import Header from '../components/Header';
import AxiosRequest from '../AxiosRequest';
import toast from 'react-hot-toast';
import NoData from '../components/NoData';
import { NavLink } from 'react-router-dom';
import placeholderImg from '../utils/placeholderImg';

const initValue = { data: [], loading: false }
const Leaderboard = () => {
  const reff = useRef()
  const [leaderboard, setLeaderboard] = useState(initValue)
  // const user = JSON.parse(localStorage.getItem("user"))

  const getPersonaDetail = async () => {
    try {
      reff.current = true
      setLeaderboard(prev => ({ ...prev, loading: true }))
      const { data } = await AxiosRequest.get(`result/leader-board`);
      const _leaderboard = data.data ? data.data.sort((a, b) => a?.rank - b?.rank) : [];
      setLeaderboard(prev => ({
        data: _leaderboard,
        loading: false
      }))
    }
    catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message || err?.message || "Something went wrong");
      setLeaderboard(initValue)
    }
  }

  useEffect(() => {
    if (!reff?.current) getPersonaDetail()
  }, [])
  return (
    <>

      <Header />
      <Container className='leaderboard-page'>
        <div className='custom-breadcrumb pt-4'>
          <Breadcrumb>
            <span className='breadcrumb-item'>
              <NavLink to={'/'}>Home</NavLink>
            </span>
            <Breadcrumb.Item active>Leaderboard</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <h4 className='fontwhite24'>Leaderboard</h4>
        <div className='leaderboard-box mb-3'>
          {leaderboard.loading ? (
            <div className='d-flex align-items-center justify-content-center py-5'>
              <span className='py-5'>
                <Spinner
                  variant='light'
                  style={{ height: "50px", width: "50px" }} />
              </span>
            </div>
          ) : (
            leaderboard.data.length === 0 ? (
              <div className='d-flex align-items-center justify-content-center py-5'>
                <span className='py-5'>
                  <NoData className='opacity-75' />
                </span>
              </div>
            ) : (
              <Row>
                <Col xxl={7} xl={7} lg={6} sm={12} className='left-section'>
                  <div className='d-flex align-items-center justify-content-evenly col mx-auto flex-wrap'>
                    <div className='col-4 col-md-auto d-flex flex-column align-items-center justify-content-center mb-3'>
                      {leaderboard.data[1] && (
                        <>
                          <p className='fontwhite20'>{leaderboard.data[1]?.rank}</p>
                          <img
                            src={leaderboard.data[1]?.user.photo || placeholderImg(leaderboard.data[1]?.user.name)}
                            alt=''
                            className='img-fluid playerimg' />
                          <p className='fontwhite16 mt-2 mb-2 text-center'>{leaderboard.data[1]?.user.name || "-"}</p>
                          <p className='fontskyblue24'>{leaderboard.data[1]?.score || 0}</p>
                        </>
                      )}
                    </div>
                    <div className='col-4 col-md-auto d-flex flex-column align-items-center justify-content-center mb-3 custom-first'>
                      {leaderboard.data[0] && (
                        <>
                          <p className='fontwhite20'>{leaderboard.data[0]?.rank}</p>
                          <img src={crownimg} alt='' className='img-fluid mb-3 taj' />
                          <img
                            src={leaderboard.data[0]?.user.photo || placeholderImg(leaderboard.data[0]?.user.name)}
                            alt=''
                            className='img-fluid playerimg' />
                          <p className='fontwhite16 mt-2 mb-2 text-center'>{leaderboard.data[0]?.user.name || "-"}</p>
                          <p className='fontskyblue24'>{leaderboard.data[0]?.score}</p>
                        </>
                      )}
                    </div>
                    <div className='col-4 col-md-auto d-flex flex-column align-items-center justify-content-center mb-3'>
                      {leaderboard.data[2] && (
                        <>
                          <p className='fontwhite20'>{leaderboard.data[2]?.rank}</p>
                          <img
                            src={leaderboard.data[2]?.user.photo || placeholderImg(leaderboard.data[2]?.user.name)}
                            alt=''
                            className='img-fluid playerimg' />
                          <p className='fontwhite16 mt-2 mb-2 text-center'>{leaderboard.data[2]?.user.name || "-"}</p>
                          <p className='fontskyblue24'>{leaderboard.data[2]?.score}</p>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xxl={5} xl={5} lg={6} sm={12} className='right-section'>
                  {leaderboard.data.filter((leaderB, i) => (i + 1 > 3) && (i + 1 < 9)).length === 0 ? (
                    <div className='h-100 d-flex align-items-center justify-content-center py-5'>
                      <NoData className='opacity-75' />
                    </div>
                  ) : (
                    leaderboard.data.filter((leaderB, i) => (i + 1 > 3) && (i + 1 < 9)).map((leaderB, i) => (
                      <Row key={i} className='mb-3 align-items-center'>
                        <Col className='col-auto fontwhite16'>{leaderB.rank}</Col>
                        <Col>
                          <div className='resultBG d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                              <div className='winnerPic'>
                                <img
                                  src={leaderB?.user?.photo || placeholderImg(leaderB?.user?.name)}
                                  alt=''
                                  className='img-fluid' />
                              </div>
                              <div className='fontwhite16 ms-2'>{leaderB?.user?.name || "-"}</div>
                            </div>
                            <div className='fontskyblue18'>{leaderB.score || 0}</div>
                          </div>
                        </Col>
                      </Row>
                    ))
                  )}
                </Col>
              </Row>
            ))}
        </div>
      </Container>
    </>
  )
}

export default Leaderboard