import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Persona from "../pages/Persona";
import UpdateProfile from "../pages/UpdateProfile";
import ProtectedRoute from "./ProtectedRoute";
import Info from "../pages/Info";
import Leaderboard from "../pages/Leaderboard";
import Congrats from "../pages/Congrats";
import MyProfile from "../pages/MyProfile";
import Round from "../pages/Round";

export default function AllRoutes() {
    return (
        <Routes>
            <Route
                path="/login/:step?"
                element={<Login />} />
            <Route
                exact
                path="/"
                element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route
                exact
                path="/contest"
                element={<ProtectedRoute><Persona /></ProtectedRoute>}
            />
            <Route
                exact
                path="/update-profile"
                element={<ProtectedRoute><UpdateProfile /></ProtectedRoute>}
            />
            <Route
                exact
                path="/my-profile"
                element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
            <Route
                exact
                path="/info"
                element={<ProtectedRoute><Info /></ProtectedRoute>} />
            <Route
                exact
                path="/leaderboard"
                element={<ProtectedRoute><Leaderboard /></ProtectedRoute>} />
            <Route
                exact
                path="/round"
                element={<ProtectedRoute><Round /></ProtectedRoute>} />
            <Route
                exact
                path="/result"
                element={<ProtectedRoute><Congrats /></ProtectedRoute>} />
        </Routes>
    );
}
