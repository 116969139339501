import React, { useEffect } from "react";
import { Breadcrumb, Button, Col, Container, Nav, Row } from "react-bootstrap";
import { BackIcon, BtnArrowIcon } from "../comman/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import quiz_click from "../assets/audio/quiz_click.wav";

const RoundStart = ({ step, setStep }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToRoundList = () => {
    navigate(`/round`, { state: { ...location.state } });
    setStep(1);
  };
  const navigateToRoundQuestions = () => {
    const quizClickAudio = new Audio(quiz_click);
    quizClickAudio.play();
    navigate(`/round`, { state: { ...location.state } });
    setStep(3);
  };

  return (
    <>
      <Container className="leaderboard-page">
        <Row className="mx-0 justify-content-between my-3">
          <Nav.Link
            onClick={navigateToRoundList}
            as={"button"}
            className="backLink text-start"
          >
            <BackIcon />
            <span className="ms-2">{location?.state?.quizTitle}</span>
          </Nav.Link>
        </Row>

        <div className="d-flex flex-column heightFull align-items-center justify-content-center text-center">
          <Col xxl={8} xl={8} lg={12} sm={12}>
            <div className="question-box">
              <div className="font50skyblue">{location?.state?.roundTitle}</div>
              <p className="font30wight mt-4">
                {location?.state?.roundDescription}
              </p>
              <Button
                variant=""
                onClick={navigateToRoundQuestions}
                type="button"
                className="next-btn mt-5 mb-3"
              >
                <span className="me-2">Start Quiz!</span>
                <BtnArrowIcon />
              </Button>
            </div>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default RoundStart;
