import React from 'react'
import { Button, Card, Col } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { NavLink, useNavigate } from 'react-router-dom';
import noImg from "../assets/images/noImg.jpg";

const HomepageCard = ({ data }) => {
  const navigate = useNavigate();
  const handleNavigate = (_data) => {
    navigate(`/round`,
      { state: { isInFlow: true, practiceQuiz: data?.practiceQuiz || false, quizId: _data._id, quizTitle: _data.title, quizImage: _data.image, attemptedRounds: data.attemptedRounds, totalRounds: data.totalRounds, sponsor: data?.sponsor } })
  }
  return (
    <Col xl={3} lg={4} md={6} sm={12} className='homepage-card'>
      <Card role='button'
        onClick={() => handleNavigate(data)}>
        <div className='mobview'>
          <div className='cardImg'>
            <Card.Img src={data.image || noImg} alt='' className='img-fluid' />
          </div>
          <Card.Body>
            <Card.Title>{data.title}</Card.Title>
            <div className='d-flex justify-content-between mt-3'>
              <ProgressBar
                min={0}
                now={data.attemptedRounds}
                max={data.totalRounds}
                style={{ width: "80%" }}
              />
              <p className='fontwhite12'>{`${data.attemptedRounds} / ${data.totalRounds}`}</p>
            </div>
          </Card.Body>
        </div>
      </Card>
    </Col>

  )
}

export default HomepageCard