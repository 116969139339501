import Swal from "sweetalert2";

const confirmPopup = async ({
  title,
  text,
  icon,
  confirmButtonText,
  action,
  cancleAction = null,
  target = null
}) => {
  await Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText,
    target
  }).then((result) => {
    if (result.isConfirmed) {
      action();
      // Handle the action you want to take after confirmation
      // Swal.fire(
      //   'Deleted!',
      //   'Your file has been deleted.',
      //   'success'
      // );
    }
    if (result.isDismissed) {
      cancleAction();
    }
  });
};

export default confirmPopup;
