import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import { Col, Container, ProgressBar, Row, Spinner } from 'react-bootstrap';
import noImg from "../assets/images/noImg.jpg";
import { True, TrueBlank } from '../assets/icons/icon';
import AxiosRequest from '../AxiosRequest';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NoData from "../components/NoData";
import textLimit from "../utils/textLimit";

const initValue = { data: [], loading: false }
const RoundList = ({ step, setStep }) => {
  const reff = useRef()
  const navigate = useNavigate()
  const location = useLocation();
  const [roundsForQuiz, setRoundsForQuiz] = useState(initValue)

  const getRoundsForQuiz = async () => {
    // const toastId = toast.loading('Please wait...');
    try {
      reff.current = true
      setRoundsForQuiz(prev => ({ ...prev, loading: true }))
      const { data } = await AxiosRequest.get(`quiz/${location?.state?.quizId}/rounds`);
      setRoundsForQuiz(prev => ({
        data: data.data,
        loading: false
      }))

      // toast.success(data.message)
    }
    catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message || err?.message || "Something went wrong");
      setRoundsForQuiz(initValue)
    } finally {
      // toast.dismiss(toastId);
    }
  }

  useEffect(() => {
    if (!reff?.current && step === 1 && location?.state?.isInFlow) getRoundsForQuiz()
  }, [step])

  const navigateToRoundStart = (obj, i) => {

    if (obj?.timesAttempted >= obj?.noOfTry) {
      toast.error("Oops! You've reached the maximum number of attempts. Please try another round.");
      return false;
    }

    var previous = roundsForQuiz.data[i - 1];
    if (previous && !previous?.isAttempted) {
      toast.error("You have not successfully completed the previous quiz.");
      return false;
    }

    navigate(`/round`, {
      state: {
        ...location.state,
        roundId: obj._id,
        roundTitle: obj.title,
        roundDescription: obj.description,
        passingMarks: obj.passingMarks,
      }
    })
    setStep(2)
  }

  return (
    <>
      <Header />
      <Container className='list-page'>
        <div className='mt-4'>
          <div className='d-flex align-items-center mb-4'>
            <div className='upload-img d-flex justify-content-center align-items-center me-3' style={{ height: "100px", width: "100px", overflow: "hidden" }}>
              <img src={location?.state?.quizImage || noImg} alt='' style={{ height: "100%", borderRadius: "16px", width: "auto" }} />
            </div>
            <div>
              <h4 className='fontwhite24 mb-3'>{location?.state?.quizTitle}</h4>
              <div className='d-flex'>
                <ProgressBar
                  min={0}
                  now={location?.state?.attemptedRounds}
                  max={location?.state?.totalRounds}
                  style={{ width: "100%" }}
                />
                <p className='fontwhite12 ms-2' style={{ whiteSpace: "nowrap" }}>
                  {`${location?.state?.attemptedRounds} / ${location?.state?.totalRounds}`}
                </p>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={12}>
              <div className='list-box'>
                <Row className='g-2'>
                  {roundsForQuiz.loading ? (
                    <div className='d-flex align-items-center justify-content-center py-5'>
                      <Spinner
                        variant='light'
                        style={{ height: "50px", width: "50px" }} />
                    </div>
                  ) : roundsForQuiz.data.length === 0 ? (
                    <div className='d-flex align-items-center justify-content-center py-5'>
                      <NoData className='opacity-75' />
                    </div>
                  ) : roundsForQuiz.data.map((item, i) => (
                    <Col key={i} lg={12} md={12} sm={12} className=''>
                      <div
                        role="button"
                        onClick={() => navigateToRoundStart(item, i)}
                        className='d-flex align-items-center p-4 listbox-inner'>
                        <div style={{ minHeight: "50px", minWidth: "50px" }}> {item.isAttempted ? <True /> : <TrueBlank />}</div>
                        <div className='ms-3'>
                          <h4 className='fontwhite24-light m-0'>{item.title}</h4>
                          <p className='fontwhite14 m-0'>{textLimit(item.description, 80)}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>


        </div>
      </Container>
    </>
  )
}

export default RoundList;