import React, { useEffect, useRef, useState } from 'react'
import RoundList from './RoundList';
import RoundStart from './RoundStart';
import { useLocation, useNavigate } from 'react-router-dom';
import RoundQuestion from './RoundQuestion';

const Round = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { isInFlow } = location.state || {};
    const [step, setStep] = useState(1)

    // window.addEventListener("beforeunload", (event) => {
    //     const message = "Are you sure you want to leave this page?";
    //     event.preventDefault();
    //     event.returnValue = message;
    //     return message;
    // });

    useEffect(() => {
        if (!isInFlow) navigate('/')
    }, [location.state])



    if (step === 1)
        return <RoundList
            step={step}
            setStep={setStep}
        />

    if (step === 2)
        return <RoundStart
            step={step}
            setStep={setStep}
        />

    if (step === 3)
        return <RoundQuestion
            step={step}
            setStep={setStep}
        />
}

export default Round