

export const BackIcon = () => (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9184 0.498428C11.4716 1.05167 11.4716 1.94865 10.9184 2.5019L3.42014 10.0002L10.9184 17.4984C11.4716 18.0517 11.4716 18.9487 10.9184 19.5019C10.3652 20.0551 9.46817 20.0551 8.91493 19.5019L0.414932 11.0019C-0.138311 10.4487 -0.138311 9.55167 0.414932 8.99843L8.91493 0.498428C9.46817 -0.0548146 10.3652 -0.0548146 10.9184 0.498428Z" fill="#42EFEB"/>
    </svg>
);

export const BtnArrowIcon = () => (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1929 8.03017C15.4381 8.17841 15.6419 8.3924 15.7836 8.65051C15.9254 8.90861 16 9.20166 16 9.5C16 9.79834 15.9254 10.0914 15.7836 10.3495C15.6419 10.6076 15.4381 10.8216 15.1929 10.9698L2.46615 18.7667C1.42609 19.4044 0 18.6759 0 17.2968V1.70315C0 0.324132 1.42609 -0.404447 2.46615 0.233324L15.1929 8.03017Z" fill="#701CCE"/>
</svg>
);

export const CloseIcon = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="#42EFEB"/>
<path d="M21.6146 19.7596L16.855 15L21.6146 10.2404C22.1285 9.72654 22.1285 8.89926 21.6146 8.3854C21.1007 7.87153 20.2735 7.87153 19.7596 8.3854L15 13.145L10.2404 8.3854C9.72654 7.87153 8.89926 7.87153 8.3854 8.3854C7.87153 8.89926 7.87153 9.72654 8.3854 10.2404L13.145 15L8.3854 19.7596C7.87153 20.2735 7.87153 21.1007 8.3854 21.6146C8.89926 22.1285 9.72654 22.1285 10.2404 21.6146L15 16.855L19.7596 21.6146C20.2735 22.1285 21.1007 22.1285 21.6146 21.6146C22.1248 21.1007 22.1248 20.2698 21.6146 19.7596Z" fill="#701CCE"/>
</svg>
);

export const TimerIcon = () => (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.51891 1.019C8.34753 1.00635 8.17575 1.00001 8.00391 1V0C8.20044 9.58276e-05 8.39691 0.00743414 8.59291 0.022L8.51891 1.019ZM10.5229 1.469C10.2024 1.3453 9.87313 1.24537 9.53791 1.17L9.75691 0.194C10.1399 0.28 10.5169 0.394 10.8829 0.536L10.5229 1.469ZM11.8929 2.179C11.7499 2.08365 11.6035 1.99359 11.4539 1.909L11.9469 1.039C12.2888 1.23274 12.616 1.45132 12.9259 1.693L12.3109 2.482C12.1753 2.37623 12.0359 2.2755 11.8929 2.18V2.179ZM13.7269 3.969C13.5291 3.68798 13.3108 3.42192 13.0739 3.173L13.7979 2.483C14.0679 2.768 14.3179 3.073 14.5449 3.393L13.7269 3.969ZM14.4709 5.321C14.4053 5.16246 14.3339 5.00636 14.2569 4.853L15.1499 4.403C15.3265 4.75409 15.4769 5.11774 15.5999 5.491L14.6499 5.804C14.5962 5.64087 14.5365 5.47976 14.4709 5.321ZM15.0009 7.828C14.9928 7.48434 14.9594 7.14174 14.9009 6.803L15.8859 6.633C15.9529 7.019 15.9919 7.411 16.0019 7.803L15.0019 7.828H15.0009ZM14.8699 9.366C14.9029 9.196 14.9299 9.027 14.9509 8.856L15.9439 8.979C15.8959 9.36915 15.819 9.75521 15.7139 10.134L14.7499 9.867C14.7959 9.702 14.8359 9.535 14.8699 9.366ZM13.9179 11.745C14.1019 11.455 14.2639 11.151 14.4039 10.837L15.3179 11.242C15.1579 11.602 14.9729 11.948 14.7629 12.28L13.9179 11.745ZM12.9539 12.95C13.0759 12.828 13.1929 12.702 13.3039 12.572L14.0619 13.225C13.9335 13.3738 13.7998 13.5179 13.6609 13.657L12.9539 12.95Z" fill="white"/>
<path d="M8.00312 1C6.852 1.00008 5.71867 1.28405 4.7035 1.82674C3.68833 2.36943 2.82267 3.1541 2.18318 4.11125C1.54369 5.0684 1.15012 6.16848 1.03732 7.31406C0.924518 8.45964 1.09597 9.61536 1.5365 10.6789C1.97703 11.7423 2.67303 12.6808 3.56285 13.4111C4.45267 14.1413 5.50886 14.6409 6.63786 14.8655C7.76686 15.0901 8.93382 15.0328 10.0354 14.6987C11.1369 14.3645 12.1391 13.7639 12.9531 12.95L13.6601 13.657C12.7299 14.5878 11.5844 15.2747 10.3252 15.657C9.06595 16.0393 7.73189 16.105 6.44119 15.8485C5.15048 15.5919 3.94298 15.021 2.92567 14.1862C1.90836 13.3515 1.11266 12.2787 0.609058 11.0629C0.105457 9.84711 -0.0904899 8.52588 0.0385779 7.21627C0.167646 5.90666 0.617743 4.6491 1.34899 3.55502C2.08024 2.46094 3.07006 1.56411 4.23075 0.944003C5.39144 0.323895 6.68717 -0.000348732 8.00312 2.81458e-07V1Z" fill="white"/>
<path d="M7.50391 3C7.63651 3 7.76369 3.05268 7.85746 3.14645C7.95123 3.24021 8.00391 3.36739 8.00391 3.5V8.71L11.2519 10.566C11.3637 10.6334 11.4447 10.7419 11.4775 10.8683C11.5104 10.9946 11.4925 11.1288 11.4278 11.2422C11.363 11.3556 11.2564 11.4391 11.1309 11.4749C11.0053 11.5108 10.8708 11.4961 10.7559 11.434L7.25591 9.434C7.17938 9.39029 7.11577 9.32712 7.07151 9.25091C7.02726 9.17469 7.00393 9.08813 7.00391 9V3.5C7.00391 3.36739 7.05658 3.24021 7.15035 3.14645C7.24412 3.05268 7.3713 3 7.50391 3Z" fill="white"/>
</svg>

);