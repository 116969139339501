import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumb, Container, Spinner } from 'react-bootstrap';
import Quizimg from "../assets/images/Quiz.png"
import Header from '../components/Header';
import { NavLink } from 'react-router-dom';
import AxiosRequest from '../AxiosRequest';
import toast from 'react-hot-toast';
import checkUrlType from "../utils/checkUrlType";


const initValue = { data: null, loading: false }
const Info = () => {
  const reff = useRef()
  const videoEl = useRef();
  const [personaDetail, setPersonaDetail] = useState(initValue)
  const user = JSON.parse(localStorage.getItem("user"))

  const getPersonaDetail = async () => {
    try {
      reff.current = true
      setPersonaDetail(prev => ({ ...prev, loading: true }))
      const { data } = await AxiosRequest.get(`persona/${user?.persona?._id}`);
      setPersonaDetail(prev => ({
        data: data.data,
        loading: false
      }))
    }
    catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message || err?.message || "Something went wrong");
      setPersonaDetail(initValue)
    }
  }

  useEffect(() => {
    if (!reff?.current) getPersonaDetail()
  }, [])

  useEffect(() => {
    if (videoEl.current) {
      videoEl.current.play().catch((error) => {
        console.error('Error attempting to play', error);
      });
    }
  }, []);
  return (
    <>
      <Header />
      <Container className='info-page'>
        <div className='custom-breadcrumb pt-4'>
          <Breadcrumb >
            <span className='breadcrumb-item'>
              <NavLink to={'/'}>Home</NavLink>
            </span>
            <Breadcrumb.Item active>Info</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <h4 className='fontwhite24 text-center my-4'>{personaDetail?.data?.name}</h4>
          <div className='info-box col-lg-6 col-md-12 col-12'>
            {personaDetail.loading ? (
              <div className='d-flex align-items-center justify-content-center py-5'>
                <Spinner
                  variant='light'
                  style={{ height: "50px", width: "50px" }} />
              </div>
            ) : !personaDetail.data ? (
              <div className='d-flex align-items-center justify-content-center py-5 text-white opacity-75'>
                No data found!
              </div>
            ) :
              <>
                {personaDetail?.data?.videoUrl ? (
                  <div className="ratio ratio-16x9">
                    {checkUrlType(personaDetail?.data?.videoUrl) === "video" &&
                      <video
                        playsInline
                        controls
                        src={personaDetail?.data?.videoUrl}
                        loop
                        ref={videoEl}
                        className="me-5" />}
                    {checkUrlType(personaDetail?.data?.videoUrl) === "image" &&
                      <img src={personaDetail?.data?.videoUrl} alt='' />}
                  </div>
                ) : (
                  <div className="text-center">
                    <div className="font50skyblue"></div>
                    <p className="font30wight mt-4">{personaDetail?.data?.description}</p>
                  </div>
                )}
              </>
            }
          </div>
        </div>
      </Container>
    </>
  )
}

export default Info