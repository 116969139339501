import axios from 'axios';
import AxiosRequest from '../AxiosRequest';

const uploadImg = async ({ imageFile }) => {
    const sasUrl = await generateSasUrl(imageFile.name.replace(/ /g, "_"));
    const headers = new Headers();
    headers.append("x-ms-blob-type", "BlockBlob");
    headers.append("Content-Type", imageFile.type);

    const response = await fetch(sasUrl, {
        method: "PUT",
        headers: headers,
        body: imageFile,
    });

    if (response.ok) {
        return sasUrl.split("?")[0];
    } else {
        return "";
    }
};

const generateSasUrl = async (fileName) => {
    const { data } = await AxiosRequest.post("users/access-signature", {
        fileName: fileName,
    });
    return data.data.blobUrl;
}

export default uploadImg