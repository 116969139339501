import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isLogin, setIsLogin] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    setIsLogin(!!userToken);
  }, [location]);

  useEffect(() => {
    if (isLogin === false) {
      // toast.error("Please login or signup");
    }
  }, [isLogin]);

  if (isLogin === null) {
    return "loading...";
  }

  if (!isLogin) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;