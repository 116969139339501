
const checkUrlType = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'];
    const videoExtensions = ['.mp4', '.webm', '.ogg', '.avi', '.mov'];

    const extension = url.split('.').pop().toLowerCase();

    if (imageExtensions.includes('.' + extension)) {
        return 'image';
    } else if (videoExtensions.includes('.' + extension)) {
        return 'video';
    } else {
        return 'unknown';
    }
}

export default checkUrlType