import { useNavigate, useSearchParams } from "react-router-dom";
import AllRoutes from "./router/AllRoutes";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/style/style.scss";
import "./assets/style/custom.scss";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("user"))
  const userToken = localStorage.getItem("userToken");

  const [query] = useSearchParams();
  const utm_source = query?.get('utm_source');
  const utm_medium = query?.get('utm_medium');
  const utm_campaign = query?.get('utm_campaign');

  if(utm_source) {
    localStorage.setItem('utm_source', utm_source)
  }

  if(utm_medium) {
    localStorage.setItem('utm_medium', utm_medium)
  }

  if(utm_campaign) {
    localStorage.setItem('utm_campaign', utm_campaign)
  }

  console.log("Brainpulse Web APP")
  return (
    <>
      <AllRoutes />
      <Toaster position="bottom-center" />
    </>
  );
}

export default App;
