import React, { useEffect } from 'react'
import { Button, Col, Container, ProgressBar } from 'react-bootstrap';
import Animation from '../components/Animation';
import { useLocation, useNavigate } from 'react-router-dom';

const Congrats = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { totalQuestions = 0, correctAns = 0, score = 0, quizTitle, result = "" } = location.state || {};

  useEffect(() => {
    if (result === "") navigate("/")
  }, [navigate, result])
  return (
    <Container>
      {/* <Animation /> */}
      <div className='congrats-page d-flex align-items-center'>
        <div className='col-lg-4 col-md-6 col-sm-12 mx-auto'>
          {result === "pass" && <Animation />}
          <div className='congrats-circle  mx-auto'>
            <p className='fontwhite24-light m-0'>Score</p>
            <h1 className='fontwhite60'>{score || 0}</h1>
          </div>
          {result === "pass" && (
            <div className='text-center mt-5'>
              <h3 className='fontwhite30'>Congratulation!</h3>
              <p className='fontwhite20-light'>You completed the {quizTitle} quiz</p>
            </div>
          )}
          {result === "fail" && (
            <div className='text-center mt-5'>
              <h3 className='fontwhite30'>Sorry!</h3>
              <p className='fontwhite20-light'>
                Look like you answered one or more questions incorrectly, Try your luck on other quiz.
              </p>
            </div>
          )}

          <div className='congrats-box mt-5'>
            <div className='d-flex justify-content-between' style={{ borderBottom: "1px solid #D3D3D3", paddingBottom: "10px" }}>
              <p className='fontwhite20-light'>Total Question</p>
              <div className='total-circle'>{totalQuestions || 0}</div>
            </div>
            <div className='justify-content-between mt-4 custom-process'>
              <div className='d-flex justify-content-between'>
                <p className='fontwhite20-light'>Correct</p>
                <p className='d-flex align-items-end' style={{ color: "#11C36A" }}> {correctAns || 0}</p>
              </div>
              <div
                class="progress"
                role="progressbar"
                aria-label="Success example"
                // aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100">
                <div
                  class="progress-bar"
                  style={{ width: `${((correctAns / totalQuestions) * 100) || 0}%` }}>
                </div>
              </div>
            </div>
            <div className='justify-content-between mt-4 custom-wrong'>
              <div className='d-flex justify-content-between'>
                <p className='fontwhite20-light'>Wrong</p>
                <p className='d-flex align-items-end' style={{ color: "#DA6723" }}>{totalQuestions - correctAns}</p>
              </div>
              <div
                class="progress"
                role="progressbar"
                aria-label="Success example"
                // aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100">
                <div
                  class="progress-bar"
                  style={{ width: `${(((totalQuestions - correctAns) / totalQuestions) * 100) || 0}%` }}>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <Col xl={4} md={6} className='mt-3'>
              <Button type='button' onClick={() => navigate('/')} className='next-btn w-100 mb-5' variant=''>
                Done
              </Button>
            </Col>
          </div>
        </div>
      </div>
    </Container>

  )
}

export default Congrats