import React, { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../assets/images/logo_rm_nxg-v1.png";
import { NavLink } from "react-router-dom";
// import TitleSponser from "../assets/images/title-sponsor-logo-v1.png";
import TitleSponser from "../assets/images/title-sponsor.png";

import toast from "react-hot-toast";

const Header = () => {
	const handleLogout = (e) => {
		e.target.disabled = true;
		localStorage.clear();
		// localStorage.setItem("notifyMsg", "Logout successful");
		window.location.reload(true);
	};

	// useEffect(() => {
	//   let notifyMsg = localStorage.getItem('notifyMsg') || "";
	//   if (notifyMsg && notifyMsg.length > 0) {
	//     toast.success(notifyMsg);
	//     localStorage.removeItem('notifyMsg')
	//   }
	// }, [])
	return (
		<Navbar expand="lg" className="custom-navbar">
			<Container className="">
				<Navbar.Brand>
					<div className="d-flex align-items-center header1">
						<NavLink to={"/"}>
							<img
								src={Logo}
								alt=""
								className="img-fluid logo1"
							/>
						</NavLink>
						<span className="vertical-line"></span>
						<img
							src={TitleSponser}
							alt=""
							className="img-fluid logo2"
						/>
					</div>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto d-flex justify-content-center align-items-center">
						<Nav.Link as={NavLink} to={"/"}>
							Home
						</Nav.Link>
						<Nav.Link as={NavLink} to={"/my-profile"}>
							Profile
						</Nav.Link>
						<Nav.Link as={"button"} onClick={handleLogout}>
							Logout
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default Header;
