import React from "react";
import { Spinner } from "react-bootstrap";

// ===== common loader UI ======
const OverlayLoader = ({ spinner = true }) => {
  return (
    <div className="overlayLoader">
      {spinner ? (
        <div className="overlay__inner">
          <div className="overlay__content">
            <Spinner as="span" variant="light" animation="border" size="lg" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OverlayLoader;
