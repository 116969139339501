import React, { useEffect, useRef, useState } from 'react';
import logo from "../assets/images/logo_rm_nxg-v1.png";
import { Button, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import AxiosRequest from '../AxiosRequest';
import { toast } from 'react-hot-toast';
import OverlayLoader from '../components/OverlayLoader';
import { useNavigate } from 'react-router-dom';
import NoData from '../components/NoData';

const initValue = { data: [], total: 0, loading: false }
const initPersonaValue = { input: "", selected: "" }
const Persona = () => {
  const reff = useRef()
  const navigate = useNavigate()
  const [persona, setPersona] = useState(initValue)
  const [isloading, setIsLoading] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(initPersonaValue)

  const getPersona = async () => {
    try {
      reff.current = true
      setPersona(prev => ({ ...prev, loading: true }))
      const { data } = await AxiosRequest.get(`persona`);
      setPersona(prev => ({
        data: data.data.data,
        total: data.data.totalCount,
        loading: false
      }))
      // toast.success(data.message)
    }
    catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message || err?.message || "Something went wrong");
      setPersona(initValue)
    }
  }

  useEffect(() => {
    if (!reff?.current) getPersona()
  }, [])

  const handleSubmitPersona = async ({ submitType, selected = null }) => {
    try {
      setIsLoading(true)
      // if (!selectedPersona?.selected?.trim() && !selectedPersona?.input?.trim()) {
      //   toast.error("Please choose persona or enter unique-code")
      //   return false;
      // }
      if (submitType === "input") {
        if (!selectedPersona?.input?.trim()) {
          toast.error("Please enter your referral code!")
          return false;
        }
      }

      const payload = { uniqueCode: selected || selectedPersona?.input?.trim() }
      const { data } = await AxiosRequest.post(`users/update-user-persona`, { ...payload });
      toast.success(data.message)

      if (data?.data?.isVerified) navigate(`/`)
      else navigate(`/update-profile`)
    }
    catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message || err?.message || "Something went wrong");
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div>
        <div className='persona-page login-page'>
          <img src={logo} alt='' className='logo mb-4' />
          <Container>
            <div className='persona-box login-box mx-auto'>
              <h4 className='fontwhite24 text-center py-5'>CHOOSE CONTEST</h4>
              <div className='right-section'>
                <div class="container">
                  <div className='d-flex justify-content-center flex-wrap'>
                    {persona.loading ? (
                      <div className='d-flex align-items-center justify-content-center py-5'>
                        <span className='text-white'>Loading...</span>
                      </div>
                    ) : persona.data.filter(per => per.uniqueCode === "Yo1IOWBMmR").length === 0 ? (
                      <div className='d-flex align-items-center justify-content-center py-2'>
                        <NoData text='No contest found!' className='opacity-75' />
                      </div>
                    ) : persona.data.filter(per => per.uniqueCode === "Yo1IOWBMmR").map((per, i) => (
                      <label key={i} htmlFor={`id-${i + 1}`}>
                        <input
                          type="radio"
                          name={`name-${i + 1}`}
                          id={`id-${i + 1}`}
                          checked={selectedPersona?.selected === per.uniqueCode}
                          onChange={() => handleSubmitPersona({ submitType: "selected", selected: per.uniqueCode })}
                        />
                        <span>{per.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className='login-form form-width m-auto'>
                  <div>
                    <span className='login-email mb-4'>Or</span>
                  </div>
                  <InputGroup className="mb-4">
                    <Form.Control
                      type='text'
                      value={selectedPersona?.input || ""}
                      placeholder="Enter your referral code"
                      onChange={e => setSelectedPersona({ input: e.target.value })}
                    />
                  </InputGroup>
                  <Button
                    type=''
                    variant=''
                    onClick={() => handleSubmitPersona({ submitType: "input" })}
                    className='next-btn w-100 mb-5' >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      {persona.loading || isloading ? <OverlayLoader spinner={true} /> : null}
    </>
  )
}

export default Persona